import {
	Query,
	ExtendedSearchViewModel,
	TitleAndDescription,
	ColumnConfiguration,
	SortOrder,
	QuerySelector,
} from "@/ExtendedSearch/interfaces/dto";
import API from "@/store/API";
import Axios, { AxiosResponse, AxiosError } from "axios";
import CONST from "@/store/const";
import { Module } from "vuex";
import { RootState } from "..";

type ExtendedSearchState = {
	updateTimestamp: number;
	savedQueries: Query[];
	isQueryBuilderVisible: boolean;
	isDatatableVisible: boolean;
	currentQuery: Query | null | Record<string, never>;
	currentQuerySelector: CONST;
	querySelectors: QuerySelector[];
	searchApiData: string;
	viewModel: ExtendedSearchViewModel | Record<string, never>;
	isModelLoaded: boolean;
	columnConfiguration: ColumnConfiguration | Record<string, never>;
	filterType: CONST;
	autoUpdate: boolean;
	autoUpdateInterval: number;
	isHistoricalData: boolean; // Persisted version. Do not expose.
	isHistoricToggleEnabled: boolean; // Local version. Do not expose.
	sortOrder: SortOrder | Record<string, never>;
};

const initialState = {
	updateTimestamp: 0,
	savedQueries: [],
	isQueryBuilderVisible: false,
	isDatatableVisible: true,
	currentQuery: null,
	currentQuerySelector: CONST.OBJECT,
	querySelectors: [],
	searchApiData: "",
	viewModel: {},
	isModelLoaded: false,
	columnConfiguration: {},
	filterType: CONST.PRODUCT,
	autoUpdate: false,
	autoUpdateInterval: 0,
	isHistoricalData: false, // Persisted version. Do not expose.
	isHistoricToggleEnabled: false, // Local version. Do not expose.
	sortOrder: {},
};

const extendedSearchModule: Module<ExtendedSearchState, RootState> = {
	state: initialState,
	getters: {
		updateTimestamp: (state) => state.updateTimestamp,
		isQueryBuilderVisible: (state) => state.currentQuery !== null,
		isDatatableVisible: (state) => state.isDatatableVisible,
		currentQuery: (state) => state.currentQuery,
		currentQuerySelector: (state) => state.currentQuerySelector,
		searchApiData: (state) => state.searchApiData,
		allSavedQueries: (state) => state.savedQueries,
		viewModel: (state) => state.viewModel,
		queryTitleAndDescription: (state): TitleAndDescription | null => {
			const query = state.currentQuery;
			if (!query?.title) return null;
			return {
				title: query.title.concat(query.role ? ` (${query.role})` : ""),
				description: query.description,
			};
		},
		isModelLoaded: (state) => state.isModelLoaded,
		columnConfiguration: (state) => state.columnConfiguration,
		filterType: (state) => state.filterType,
		querySelectors: (state) => state.querySelectors,
		autoUpdate: (state) => state.autoUpdate,
		autoUpdateInterval: (state) => state.autoUpdateInterval,
		isHistoric: (state) => state.isHistoricToggleEnabled,
		sortOrder: (state) => state.sortOrder,
	},
	mutations: {
		setUpdateTimestamp: (state) => (state.updateTimestamp = Date.now()),
		setListOfSavedQueries: (state, listOfQueries: Query[]) => (state.savedQueries = listOfQueries),
		setDatatableVisibility: (state, status: boolean) => (state.isDatatableVisible = status),
		setCurrentQuery: (state, query: Query | null) => {
			state.currentQuery = query;
			if (query) {
				state.autoUpdate = query.autoUpdate;
				state.autoUpdateInterval = query.autoUpdateInterval;
			}
		},
		setCurrentQuerySelector: (state, selector: string) => (state.currentQuerySelector = selector),
		setSearchApiData: (state, query: string) => (state.searchApiData = query),
		setViewModel: (state, model: ExtendedSearchViewModel) => (state.viewModel = model),
		setModelLoaded: (state, status: boolean) => (state.isModelLoaded = status),
		setColumnConfiguration: (state, config: ColumnConfiguration) => (state.columnConfiguration = config),
		setFilterType: (state, type: string) => (state.filterType = type),
		setQuerySelectors: (state, selectors) => (state.querySelectors = selectors),
		setAutoUpdate: (state, status) => (state.autoUpdate = status),
		setAutoUpdateInterval: (state, interval) => (state.autoUpdateInterval = interval),
		setIsHistoricalData: (state, status) => (state.isHistoricalData = status),
		setIsHistoricToggleEnabled: (state, status) => (state.isHistoricToggleEnabled = status),
		setSortOrder: (state, sortOptions: SortOrder) => (state.sortOrder = sortOptions),
	},
	actions: {
		async getAndSetQueries({ commit }) {
			await Axios.get(API.GetAllQueries())
				.then((response: AxiosResponse<Query[]>) => {
					commit("setListOfSavedQueries", response.data);
				})
				.catch((error: AxiosError) => {
					window.console.error("Extended Search: Error occured while loading all queries.", error);
				});
		},
		setSavedQueries({ commit }, queries: Query[]) {
			commit("setListOfSavedQueries", queries);
		},
		setDatatableVisibility({ commit }, visibility: boolean) {
			commit("setDatatableVisibility", visibility);
		},
		setCurrentQuery({ commit }, query: Query) {
			commit("setCurrentQuery", query);
		},
		async getAndSetViewModel({ commit }) {
			await Axios.get(API.GetViewModel())
				.then((response: AxiosResponse<ExtendedSearchViewModel>) => {
					commit("setViewModel", response.data);
					commit("setModelLoaded", true);
				})
				.catch((error: AxiosError) => {
					window.console.error("Extended Search: Error occured while loading view model.", error);
				});
		},
		setSearchApiData({ commit }, data: string) {
			commit("setSearchApiData", data);
		},
		setCurrentQuerySelector({ commit }, type: string) {
			commit("setCurrentQuerySelector", type);
		},
		setColumnConfiguration({ commit }, config: string) {
			commit("setColumnConfiguration", config);
		},
		setFilterType({ commit }, type: string) {
			commit("setFilterType", type);
		},
		setSelectors({ commit }, selectors) {
			commit("setQuerySelectors", selectors);
		},
		setAutoUpdate({ commit }, status: boolean) {
			commit("setAutoUpdate", status);
		},
		setAutoUpdateInterval({ commit }, interval: number) {
			commit("setAutoUpdateInterval", interval);
		},
		setIsHistoricalData({ commit }, status: boolean) {
			commit("setIsHistoricalData", status);
		},
		setIsHistoricToggleEnabled({ commit }, status: boolean) {
			commit("setIsHistoricToggleEnabled", status);
		},
		setSortOrder({ commit }, status: SortOrder) {
			commit("setSortOrder", status);
		},
		resetExtendedSearchState: ({ state }) => (state = initialState),
	},
};

export default extendedSearchModule;
