import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { loadLanguageAsync } from "@/i18n";
import { Modules } from "@/store/modules/account";
import store from "@/store";

Vue.use(VueRouter);

const DEFAULT_WINDOW_TITLE = "INDUTRAX AppEnv";

export const ROUTER_PARAM_KEYS = {
	REPORT: "reportid",
} as const;

export const routes: Array<RouteConfig> = [
	{
		path: "/livemap",
		name: "MapApp",
		meta: {
			title: "LiveMap",
			rolePage: "LiveMap",
			icon: "fas fa-map-location-dot",
			requireClaim: true,
		},
		alias: ["/map"],
		component: () => import("../views/MapApp.vue"),
	},
	{
		path: "/liveschema",
		name: "LiveSCHEMA",
		meta: {
			title: "LiveSchema",
			rolePage: "LiveSchema",
			icon: "fas fa-project-diagram nav-icon",
			modules: [Modules.LiveSchema],
			requireClaim: true,
		},
		component: () => import("../views/LiveSCHEMA.vue"),
	},
	{
		path: "/objectfinder",
		name: "ObjectFINDER",
		meta: {
			title: "ObjectFinder",
			rolePage: "ExtendedSearch",
			requireClaim: true,
		},
		component: () => import("../views/ExtendedSearch.vue"),
	},
	{
		path: "/shopfloor",
		name: "ShopFloorUI",
		meta: {
			title: "ShopFloorUI",
			rolePage: "ShopFloor",
			icon: "far fa-scanner-touchscreen",
			modules: [Modules.ShopFloor],
			requireClaim: true,
		},
		component: () => import("../views/ShopFloor.vue"),
	},
	{
		path: `/reports/:${ROUTER_PARAM_KEYS.REPORT}?`,
		name: "Reports",
		meta: {
			title: "Reports",
			rolePage: "Reports",
			icon: "fas fa-chart-line-up",
			modules: [Modules.Reports],
			requireClaim: true,
		},
		component: () => import("../views/Reports.vue"),
	},
	{
		path: "/objectmanager",
		name: "ObjectManager",
		meta: {
			title: "ObjectManager",
			rolePage: "ObjectManager",
			requireClaim: true,
		},
		component: () => import("../views/ObjectManager.vue"),
	},
	{
		path: "/eventviewer",
		name: "EventViewer",
		meta: {
			title: "EventViewer",
			rolePage: "EventViewer",
			icon: "fas fa-comment-check",
			modules: [Modules.EventManagement],
			requireClaim: true,
		},
		component: () => import("../views/EventViewer.vue"),
	},
	{
		path: "/shiftmanager",
		name: "ShiftManager",
		meta: {
			title: "ShiftManager",
			rolePage: "ShiftManager",
			icon: "fas fa-calendar-range",
			modules: [Modules.ActivitiesAndShifts],
			requireClaim: true,
		},
		component: () => import("../views/ShiftManager.vue"),
	},
	{
		path: "/systemmonitor",
		name: "SystemMonitor",
		meta: {
			title: "SystemMonitor",
			rolePage: "SystemMonitor",
			icon: "far fa-tower-control",
			modules: [Modules.SystemMonitor, Modules.Debug],
			requireClaim: true,
		},
		component: () => import("../views/SystemMonitor.vue"),
	},
	{
		path: "/digitalpicker",
		name: "DigitalPICKER",
		meta: {
			title: "DigitalPicker",
			rolePage: "DigitalPicker",
			icon: "fas fa-dog",
			modules: [Modules.Debug],
			requireClaim: true,
		},
		component: () => import("../DigitalPicker/DigitalPicker.vue"),
	},
];

export const nonSidebarRoutes: Array<RouteConfig> = [
	{
		path: "/profile",
		name: "UserProfile",
		meta: {
			title: "UserProfile",
			icon: "fas fa-user",
			requireClaim: false,
		},
		component: () => import("../views/UserProfile.vue"),
	},
	{
		path: "/404",
		name: "ViewNotFound",
		meta: {
			title: "ViewNotFound",
			icon: "fas fa-blank",
			requireClaim: false,
		},
		component: () => import("../views/Errors/ViewNotFound.vue"),
	},
];

const router = new VueRouter({
	routes: routes.concat(nonSidebarRoutes),
});

router.beforeEach(async (to, from, next) => {
	await ensureLogin();

	// Load Languages lazily
	if ("lang" in localStorage) {
		loadLanguageAsync(localStorage["lang"]);
	} else {
		loadLanguageAsync("en");
	}

	if (isRouteAllowed(routes.concat(nonSidebarRoutes).find((r) => r.name == to.name))) {
		next();
	} else {
		navigateToDefaultPage(from, to, next);
	}
});

router.afterEach((to, from) => {
	document.title = [to.meta.title, DEFAULT_WINDOW_TITLE].join(" | ") || DEFAULT_WINDOW_TITLE;
});

async function ensureLogin(): Promise<void> {
	const loaded: boolean = store.getters["Account/loaded"];
	if (!loaded) {
		await store.dispatch("Account/fetchAccount", null, null);
	}
}

export function getDefaultRoute(name: string = null): RouteConfig {
	if (name == null) {
		return routes.find((r) => isRouteAllowed(r));
	}
	return routes.find((r) => r.meta?.rolePage && r.meta?.rolePage == name);
}

export function navigateToDefaultPage(from, to, next) {
	const defaultPage: string = store.getters["Account/defaultPage"];
	const defaultRoute = getDefaultRoute(defaultPage);
	if (defaultRoute && isRouteAllowed(defaultRoute)) {
		next({ name: defaultRoute.name });
	} else {
		const target = getDefaultRoute();
		if (target != null) {
			next({ name: target.name });
		} else {
			next({ name: "ViewNotFound" });
		}
	}
}

export function isRouteAllowed(route: RouteConfig): boolean {
	if (!route) {
		return false;
	}
	const installed: Modules[] = store.getters["Account/modules"];
	const isAdmin: boolean = store.getters["Account/isAdmin"];
	const claims: string[] = store.getters["Account/claims"];
	const moduleInstalled =
		((route.meta?.modules as string[]) ?? []).length == 0 || route.meta.modules.some((m: Modules) => installed.includes(m));
	const userHasAccess =
		isAdmin || !route.meta?.requireClaim || !route.meta?.hasOwnProperty("rolePage") || claims.includes(route.meta?.rolePage);
	return moduleInstalled && userHasAccess;
}

export default router;
