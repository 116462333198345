export default class API {
	// Enable for prod
	static baseURI = "";
	// Enable for debug
	// static baseURI = "http://localhost";

	static currentVersionAPI = `${API.baseURI}api/v2/` as const;

	static logout = `${API.baseURI}account/logout` as const;

	static accountInformation = `${API.baseURI}api/account` as const;
	static accountChangePassword = `${API.baseURI}account/changepassword` as const;

	static logs = `${API.baseURI}api/monitoring/logsroot` as const;

	// Needs to be ported to new API
	static mapViews = `${API.baseURI}api/userpreferences/views` as const;

	// Hubs
	static hubs = `${API.baseURI}hubs/` as const;
	static mapHub = `${API.hubs}maphub` as const;
	static comHub = `${API.hubs}communicationhub` as const;
	static tagHub = `${API.hubs}tagshub` as const;
	static recognizerMonitorHub = `${API.hubs}recognizermonitor` as const;
	static digitalPickerHub = `${API.hubs}digitalpickerhub` as const;

	static extendedSearch = `${API.baseURI}api/extendedsearch` as const;

	static licenses = {
		frontend: `${API.baseURI}licenses/json/frontend.json` as const,
		server: `${API.baseURI}licenses/json/server.json` as const,
		licensesUrl: (item: string) => `${API.baseURI}licenses/${item.replace("/", "_").replace(" ", "_")}.txt`,
	};

	static map = {
		views: {
			default: `${API.baseURI}api/map/defaultview` as const,
			site: `${API.baseURI}api/map/siteview` as const,
			views: `${API.baseURI}api/map/views` as const,
			viewByName: (name: string) => `${API.map.views.views}/${name}` as const,
		},
		search: `${API.baseURI}api/map/search` as const,
		backgrounds: `${API.baseURI}api/map/backgrounds.json` as const,
		config: `${API.baseURI}api/map/config.json` as const,
		controllers: `${API.baseURI}api/map/controllers.js` as const,
		translations: `${API.baseURI}api/map/translations.json` as const,
		visuals: `${API.baseURI}api/map/visuals.json` as const,
		layerlist: `${API.baseURI}api/map/views/layerlist` as const,
		objvisu: {
			configs: `${API.baseURI}api/map/objvisu/configs` as const,
			svgs: `${API.baseURI}api/map/objvisu/svgs` as const,
		},
	};

	//#region RestAPI
	static model = {
		sites: `${API.currentVersionAPI}sites` as const,
		pcs: `${API.currentVersionAPI}pcs` as const,
		gateZones: `${API.currentVersionAPI}gatezones` as const,
		positionableObjectSpecs: `${API.currentVersionAPI}posostypes` as const,
		tagSpecs: `${API.baseURI}api/map/tag/types` as const,
		labelSpecs: `${API.baseURI}api/map/elabel/types` as const,
		fixedZones: `${API.currentVersionAPI}fixedzones` as const,
		version: `${API.currentVersionAPI}system/version` as const,
		login: `${API.baseURI}api/account/login` as const,
		productionAreasAll: `${API.currentVersionAPI}productionareas` as const,
	};

	static theme = {
		colors: "api/theme/colors.json",
		customCss: "api/theme/custom.css",
		logo: "api/theme/logo.png",
		logoText: "api/theme/logotext.png",
		logoLogin: "api/theme/logologin.png",
	};

	static posos = `${API.currentVersionAPI}posos` as const;
	static pososDeactivated = `${API.baseURI}api/reporting/objecthistory/deactivatedposos` as const;
	static posoById = (id: string) => `${API.posos}/${id}` as const;
	static reportConfig = `${API.baseURI}api/reporting/configuration` as const;
	static managedPropertyValues = `${API.baseURI}api/reporting/ManagedProperties` as const;
	//#endregion

	// Reports - #start
	static reportConfigurations = `${API.baseURI}api/reporting/configurations` as const;
	static reportActivityOccupancy = `${API.baseURI}api/reporting/activities/occupancy` as const;
	static reportActivityLoadDrillThrough = (id) => `${API.baseURI}api/reporting/activities/objects/${id}` as const;
	static reportZoneOccupancy = `${API.baseURI}api/reporting/fixedzones/occupancy` as const;
	static reportZoneOccupancyDrillThrough = (id: string) => `${API.baseURI}api/reporting/fixedzones/objects/${id}` as const;
	static reportObjectHistory = (id: string, includeHierarchy: boolean = false) =>
		`${API.baseURI}api/reporting/objecthistory/history/${id}/${includeHierarchy}` as const;
	static clientFilesAccess = `${API.baseURI}api/reporting/additionaldata` as const;
	static reportProductionProgress = (id: string) => `${API.baseURI}api/reporting/activities/productionprogress/${id}` as const;
	static reportActivityHistory = (id: string) => `${API.baseURI}api/reporting/activities/activityhistory/${id}` as const;
	static reportActivityDurations = `${API.baseURI}api/reporting/activities/activitydurations` as const;
	static reportObjectZoneHistory = (id: string) => `${API.baseURI}api/reporting/fixedzones/zonehistory/${id}` as const;
	static reporting = {
		positions: {
			significantPositions: `${API.baseURI}api/reporting/positions/significantpositions` as const,
			significantPositionsCount: `${API.baseURI}api/reporting/positions/significantpositions/count` as const,
		},
		posos: `${API.baseURI}api/reporting/posos` as const,
		managedProperties: {
			info: `${API.baseURI}api/reporting/ManagedProperties/propertyinfo` as const,
		},
	};
	// Reports - #end

	static tryMarry = (id: string, tagid: string) => `${API.currentVersionAPI}posos/${id}/tags/${tagid}` as const;
	static tryMarryQuery = (id: string, tagid: string, origin: string) =>
		`${API.currentVersionAPI}posos/${id}/tags/${tagid}?origin=${origin}` as const;
	static tryDivorce = (id: string, tagid: string) => `${API.currentVersionAPI}posos/${id}/tags/${tagid}` as const;
	static tryCouple = (id: string, objectid: string) => `${API.currentVersionAPI}posos/${id}/objects/${objectid}` as const;
	static tryDecouple = (id: string, objectid: string) => `${API.currentVersionAPI}posos/${id}/objects/${objectid}` as const;
	static tryDelete = (id: string) => `${API.currentVersionAPI}posos/${id}` as const;
	static tryReactivate = (id: string) => `${API.baseURI}api/posos/${id}/reactivate` as const;
	static posoELabels = (id: string) => `${API.currentVersionAPI}posos/${id}/elabels` as const;
	static posoELabel = (id: string, eLabelId: string) => `${API.currentVersionAPI}posos/${id}/elabels/${eLabelId}` as const;

	static modelTreeItems = `${API.currentVersionAPI}/views/treeitems` as const;

	static shiftManager = {
		range: `${API.baseURI}api/shiftmanagerapi/range` as const,
	};

	static configuration = `${API.baseURI}configui` as const;

	static SearchObjects = (type: string, isHistoric: boolean) => `${API.extendedSearch}/search/${type}/${isHistoric}` as const;
	static SaveQuery = () => `${API.extendedSearch}/saveQuery` as const;
	static DeleteQuery = (id: string) => `${API.extendedSearch}/delete/${id}` as const;
	static GetAllQueries = () => `${API.extendedSearch}/getAllQueries` as const;
	static GetViewModel = () => `${API.extendedSearch}/getViewModel` as const;
	static MapURL = (ids) => `/${API.baseURI}map#${ids}` as const;

	//#region shopFloor
	static workstations = () => `${API.baseURI}api/shopfloor/workstations` as const;
	static posoURL = () => `${API.baseURI}api/poso` as const;
	static BarcodeUrl = () => `${API.baseURI}api/barcode` as const;
	static workstationURL = () => `${API.baseURI}api/workstations` as const;
	static verifyObject = (objectId: string, workstationId: string, type: string) =>
		`${API.workstationURL()}/${workstationId}/object/${objectId}/${type}/verify` as const;
	static validateTag = (qualifiedTagId: string, workstationId: string) =>
		`${API.workstationURL()}/${workstationId}/tags/${qualifiedTagId}/validate` as const;
	static FetchOrCreateObject = (objectId: string, type: string, workstationId: string) =>
		`${API.workstationURL()}/${workstationId}/object/${type}/${objectId}` as const;
	static SendCmdGate = () => `${API.BarcodeUrl()}/gate` as const;
	static SendCmdMarry = () => `${API.BarcodeUrl()}/marry` as const;
	static SendCmdDivorce = () => `${API.BarcodeUrl()}/divorce` as const;
	static SendCmdCouple = () => `${API.BarcodeUrl()}/couple` as const;
	static SendCmdCreate = (readerId: string, objectId: string, subtype: string) =>
		`${API.BarcodeUrl()}/create/${readerId}/${objectId}/${subtype}` as const;
	static SendCmdGateMarry = () => `${API.BarcodeUrl()}/gatemarry` as const;
	static SendCmdGateCouple = () => `${API.BarcodeUrl()}/gatecouple` as const;
	static SendCmdLink = () => `${API.BarcodeUrl()}/link` as const;
	static SendCmdUnLink = () => `${API.BarcodeUrl()}/unlink` as const;
	static SendCmdScanObject = () => `${API.BarcodeUrl()}/scanobject` as const;
	static SendCmdScanObjects = () => `${API.BarcodeUrl()}/scanobjects` as const;
	static SendCmdScanObjectAndTag = () => `${API.BarcodeUrl()}/scanobjectandtag` as const;
	static SendCmdScanToDevicePosition = () => `${API.BarcodeUrl()}/scantodeviceposition` as const;
	//#endregion

	static events = `${API.currentVersionAPI}events` as const;

	static searchMatchingObjectIds = (searchText: string) =>
		`${API.posos}/?itemsPerPage=1000&filterkey[]=id&filtercomp[]=cont&filtervalue[]=${searchText}` as const;

	//reporting
	static reportingURI = `${API.baseURI}api/reporting` as const;

	static objectHistoryReport = (id: string) => `${API.reportingURI}/objectReport/${id}` as const;
	static objectSearch = (text: string) => `${API.reportingURI}/search/${text}` as const;

	//presense detection
	static presenceDetection = {
		fixedReaders: `${API.baseURI}api/presencedetection/fixedreaders` as const,
		hub: `${API.baseURI}hubs/presencedetectionhub` as const,
		objectValues: `${API.baseURI}api/presencedetection/objectvalues` as const,
	};

	static releaseNotes = `${API.baseURI}releasenotes/releasenotes.txt` as const;

	// Commissions

	static commissions = {
		api: `${API.baseURI}api/commissions` as const,
		config: `${API.baseURI}api/commissions/config` as const,
		getCommission: (id: string) => `${API.commissions.api}/${id}` as const,
		startCommission: (commissionId: number) => `${API.commissions.api}/${commissionId}/start` as const,
		startArea: (commissionId: number, areaId: number, cartId: string) =>
			`${API.commissions.api}/${commissionId}/startarea/${areaId}/${cartId}` as const,
		pick: (commissionId: number) => `${API.commissions.api}/${commissionId}/pick` as const,
		error: (commissionId: number) => `${API.commissions.api}/${commissionId}/error` as const,
		reset: (commissionId: number) => `${API.commissions.api}/${commissionId}/reset` as const,
		endArea: (commissionId: number, areaId: number) => `${API.commissions.api}/${commissionId}/endarea/${areaId}` as const,
		endCommission: (commissionId: number) => `${API.commissions.api}/${commissionId}/end` as const,
		enablePTLLights: (commissionId: number) => `${API.commissions.api}/${commissionId}/lights/enable` as const,
		enablePTLButtons: (commissionId: number) => `${API.commissions.api}/${commissionId}/buttons/enable` as const,
	};

	static digitalPicker = {
		api: `${API.baseURI}api/digitalPicker` as const,
		config: `${API.baseURI}api/digitalPicker/config` as const,
		getCommission: (id: string) => `${API.digitalPicker.api}/${id}` as const,
	};

	static account = {
		account: "api/account" as const,
		login: "api/account/login" as const,
		loginExternal: "api/account/login/external" as const,
		logout: "api/account/logout" as const,
	};

	static systemMonitor = {
		tags: {
			get: `${API.currentVersionAPI}tags` as const,
			comment: {
				put: (fullQualifiedId: string) => `${API.currentVersionAPI}tags/${fullQualifiedId}/comment` as const,
			},
		},
	};

	static modelValid = `${API.currentVersionAPI}system/modelvalid` as const;
}
