export default class CONST {
	static MEDIATYPE_JSON = "application/json";
	static INFO = "info";
	static ERROR = "error";
	static DUPLICATE_TITLE = "duplicate_title";
	static OBJECT = "object";
	static ZONE = "zone";
	static ACTIVITY = "activity";
	static PRODUCT = "Products";
	static MEANSOFTRANSPORT = "Means of transport";
	static PRODUCTIONRESOURCE = "Production ressources and tools";
	static TRANSPORTVEHICLE = "Transport vehicles";
	static STRING = "System.String";
	static INT32 = "System.Int32";
	static INT64 = "System.Int64";
	static DOUBLE = "System.Double";
	static FLOAT = "System.Float";
	static DATETIME = "System.DateTime";
	static BOOLEAN = "System.Boolean";
	static ZOOM_LEVEL = 24;
}
